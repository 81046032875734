import { Button, Form, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import RevenueShareModel from './model';

// const
const RevenueShare = () => {
  const [disabled, setDisabled] = useState(true);
  const store = useMemo(() => new RevenueShareModel(), []);
  const { setRef, onSave, handleChange } = store;

  return (
    <div className="pl-20 pt-10 bg-white ">
      <Form
        ref={setRef}
        labelCol={{ className: 'font-800' }}
        disabled={disabled}
      >
        <div>
          <div className="flex">
            <Form.Item
              label="拉新主播"
              name="invite_anchor_amount"
            >
              <InputNumber
                placeholder="请输入"
                className="w-50 ml-5"
                min={0}
                step={1}
                precision={2}
                changeOnBlur={true}
                onChange={(e) => {
                  handleChange(e, 'invite_anchor_amount');
                }}
              />
            </Form.Item>
            <span className="ml-2 mt-1">元</span>
          </div>
          <div className="mb-10 leading-[0px]">
            说明：拉新主播完成注册，上传简历且审核通过（基础资料、切片、形象照均审核通过）
          </div>
          <div className="flex">
            <Form.Item
              label="撮合到面"
              name="match_to_face_proportion"
            >
              <InputNumber
                min={0}
                max={100}
                step={1}
                precision={0}
                placeholder="请输入"
                className="w-50 ml-5"
                changeOnBlur={true}
                onChange={(e) => {
                  handleChange(e, 'match_to_face_proportion');
                }}
              />
            </Form.Item>
            <span className="ml-2 mt-1">%</span>
          </div>
          <div className="mb-10 leading-[0px]">
            说明：撮合到面分账=到面服务单价*比例；分账标准：主播报名选择该经纪人，且雇主确认到面
          </div>
          <div className="flex">
            <Form.Item
              label="报名服务"
              name="sign_up_choose_broker_service_proportion"
            >
              <InputNumber
                placeholder="请输入"
                className="w-50 ml-5"
                min={0}
                max={100}
                step={1}
                precision={0}
                changeOnBlur={true}
                onChange={(e) => {
                  handleChange(e, 'sign_up_choose_broker_service_proportion');
                }}
              />
            </Form.Item>
            <span className="ml-2 mt-1">%</span>
          </div>
          <div className="mb-10 leading-[0px]">
            说明：报名服务分账=该经纪人服务主播价格*比例；分账标准：主播报名选择该经纪人，且雇主确认邀约
          </div>
          <div className="flex">
            <Form.Item
              label="主播购买畅联卡"
              name="invite_buy_pay_sign_up_service_proportion"
            >
              <InputNumber
                placeholder="请输入"
                className="w-50 ml-5"
                min={0}
                max={100}
                step={1}
                precision={0}
                changeOnBlur={true}
                onChange={(e) => {
                  handleChange(e, 'invite_buy_pay_sign_up_service_proportion');
                }}
              />
            </Form.Item>
            <span className="ml-2 mt-1">%</span>
          </div>
          <div className="mb-10 leading-[0px]">
            说明：主播购买畅联卡分账=主播购买畅联卡价格*比例；分账标准：主播通过该经纪人分享的链接购买畅联卡，且主播支付成功
          </div>
        </div>
        <div className="font-800 text-16">注册躺赚</div>
        <div className="mt-5">
          <div className="flex">
            <Form.Item
              label="报名选经纪人"
              name="sign_up_pay_service_proportion"
            >
              <InputNumber
                placeholder="请输入"
                className="w-50 ml-12"
                min={0}
                max={100}
                step={1}
                precision={0}
                changeOnBlur={true}
                onChange={(e) => {
                  handleChange(e, 'sign_up_pay_service_proportion');
                }}
              />
            </Form.Item>
            <span className="ml-2 mt-1">%</span>
          </div>
          <div className="mb-10 leading-[0px]">
            说明：主播报名选经纪人分账=经纪人服务主播价格*比例；分账标准：主播支付成功，且雇主确认邀约；
          </div>
          <div className="flex">
            <Form.Item
              label="购买自主报名"
              name="buy_pay_sign_up_service_proportion"
            >
              <InputNumber
                placeholder="请输入"
                className="w-50 ml-12"
                min={0}
                max={100}
                step={1}
                precision={0}
                changeOnBlur={true}
                onChange={(e) => {
                  handleChange(e, 'buy_pay_sign_up_service_proportion');
                }}
              />
            </Form.Item>
            <span className="ml-2 mt-1">%</span>
          </div>
          <div className="mb-10 leading-[0px]">
            说明：主播购买自主报名分账=自主报名单价*比例；分账标准：主播支付成功；
          </div>
          <div className="flex">
            <Form.Item
              label="购买全国主播交流群"
              name="buy_social_group_service_proportion"
            >
              <InputNumber
                placeholder="请输入"
                className="w-50 ml-3"
                min={0}
                max={100}
                step={1}
                precision={0}
                changeOnBlur={true}
                onChange={(e) => {
                  handleChange(e, 'buy_social_group_service_proportion');
                }}
              />
            </Form.Item>
            <span className="ml-2 mt-1">%</span>
          </div>
          <div className="mb-10 leading-[0px]">
            说明：购买全国主播交流群分账=全国主播交流群单价*比例；分账标准：主播支付成功；
          </div>
          <div className="flex">
            <Form.Item
              label="雇主付费自主邀约"
              name="employer_pay_own_invite_proportion"
            >
              <InputNumber
                placeholder="请输入"
                className="w-50 ml-5"
                min={0}
                max={100}
                step={1}
                precision={0}
                changeOnBlur={true}
                onChange={(e) => {
                  handleChange(e, 'employer_pay_own_invite_proportion');
                }}
              />
            </Form.Item>
            <span className="ml-2 mt-1">%</span>
          </div>
          <div className="mb-10 leading-[0px]">
            说明：雇主付费自主邀约分账=付费自主邀约1次单价*比例；分账标准：雇主成功消耗付费自主邀约次数；
          </div>
          <div className="flex">
            <Form.Item
              label="雇主付费人工邀约"
              name="employer_pay_artificial_invite_proportion"
            >
              <InputNumber
                placeholder="请输入"
                className="w-50 ml-5"
                min={0}
                max={100}
                step={1}
                precision={0}
                changeOnBlur={true}
                onChange={(e) => {
                  handleChange(e, 'employer_pay_artificial_invite_proportion');
                }}
              />
            </Form.Item>
            <span className="ml-2 mt-1">%</span>
          </div>
          <div className="mb-8 leading-[0px]">
            说明：雇主付费人工邀约分账=到面服务单价*比例；分账标准：雇主人工邀约，且雇主确认到面
          </div>
        </div>
      </Form>
      <Button
        type="default"
        className="ml-10"
        onClick={() => {
          setDisabled(false);
        }}
      >
        编辑
      </Button>
      <Button
        type="primary"
        className="ml-10"
        disabled={disabled}
        onClick={() => {
          setDisabled(true);
          onSave();
        }}
      >
        保存
      </Button>
    </div>
  );
};

export default observer(RevenueShare);
